import * as React from 'react';
import styled from 'styled-components';
import { IndexLayout } from '../layouts';
import { NavBar } from '../components/segments/NavBar';
import { Helmet } from 'react-helmet';
import { theme } from '../utils/theme';
import DarkFooter from '../components/segments/DarkFooter';
import { NewAppText, NewAppTitle, NewAppButton } from '../components/ui';
import { Link } from 'gatsby';
import { mobileSize, laptopSize, OFFICE_ADDRESS, OFFICE_STATE_ZIP } from '../utils';
import linkedInLogo from '../images/linkedinWhite.svg';
import facebookLogo from '../images/facebookWhite.svg';
import twitterLogo from '../images/twitterWhite.svg';
import socialsBackground from '../images/socialBgBlue.svg';
import * as Yup from 'yup';
import { Formik, FormikProps, Field } from 'formik';
import { api } from '../backend/hubspotApi';
import Select from 'react-select';
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const companyOptions = [
  { label: 'Idea Phase Startup', value: 'Idea Phase Startup' },
  { label: 'Development Phase Startup', value: 'Development Phase Startup' },
  { label: 'Existing Business', value: 'Existing Business' },
];

const formSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required.'),
  lastname: Yup.string().required('Last name is required.'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().required('Email is required.').email('Email format is not valid'),
  company: Yup.string().required('Company is required.'),
  // organization_type: Yup.string().required('* Company type is required.'),
});

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: '#3b474d',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#3b474d',
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: theme.SKY_BLUE,
  }),
};

interface MyFormikProps {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  company: string;
  organization_type: string;
  message: string;
}

export default () => {
  return (
    <NewIndexLayout>
      <Helmet>
        <meta name="title" content={'Terms of Service'} />
        <title>{'Rapptr Labs - Terms of Service'}</title>
        <meta name="description" content={'Terms of Service'} />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <HeaderText>Contact Us</HeaderText>
      <DocContainer>
        <SegmentContainer>
          <SegmentContent>
            <FormWrapper>
              <SectionTitle>Let's Talk</SectionTitle>
              <SectionText>
                From high-growth startups to enterprise brands, we help companies of all sizes
                launch digital products that drive user engagement. We’d love to discuss how we can
                help you.
              </SectionText>
              <FormContainer>
                <Formik
                  initialValues={{
                    firstname: '',
                    lastname: '',
                    phone: '',
                    email: '',
                    company: '',
                    organization_type: '',
                    message: '',
                  }}
                  validationSchema={formSchema}
                  onSubmit={async (values) => {
                    // alert(JSON.stringify(values, null, 2));
                    await api.sendForm(values);
                    // trackCustomEvent({
                    //   action: 'Successful contact us form submission',
                    //   category: 'Homepage form',
                    //   label: 'Sucessful contact us form submission',
                    // });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }: FormikProps<MyFormikProps>) => (
                    <form onSubmit={handleSubmit}>
                      <FormDiv>
                        <FormField>
                          <InputDiv>
                            <Input
                              id="firstname"
                              type="firstname"
                              name="firstname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstname}
                              placeholder="First Name"
                              // onClick={() =>
                              //   trackCustomEvent({
                              //     action: 'First name form field click',
                              //     category: 'Homepage form',
                              //     label: 'First name form field click',
                              //   })
                              // }
                            />
                            {errors.firstname && touched.firstname ? (
                              <ErrorText>{errors.firstname}</ErrorText>
                            ) : (
                              <ErrorSpace />
                            )}
                          </InputDiv>
                          <InputDiv>
                            <Input
                              id="lastname"
                              type="lastname"
                              name="lastname"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastname}
                              // onClick={() =>
                              //   trackCustomEvent({
                              //     action: 'Last name form field click',
                              //     category: 'Homepage form',
                              //     label: 'Last name form field click',
                              //   })
                              // }
                            />
                            {errors.lastname && touched.lastname ? (
                              <ErrorText>{errors.lastname}</ErrorText>
                            ) : (
                              <ErrorSpace />
                            )}
                          </InputDiv>
                        </FormField>
                        <FormField>
                          <InputDiv>
                            <Input
                              id="phone"
                              type="phone"
                              name="phone"
                              placeholder="Phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              // onClick={() =>
                              //   trackCustomEvent({
                              //     action: 'phone form field click',
                              //     category: 'Homepage form',
                              //     label: 'phone form field click',
                              //   })
                              // }
                            />
                            {errors.phone && touched.phone ? (
                              <ErrorText>{errors.phone}</ErrorText>
                            ) : (
                              <ErrorSpace />
                            )}
                          </InputDiv>
                          <InputDiv>
                            <Input
                              id="email"
                              type="email"
                              name="email"
                              placeholder="Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              // onClick={() =>
                              //   trackCustomEvent({
                              //     action: 'email form field click',
                              //     category: 'Homepage form',
                              //     label: 'email form field click',
                              //   })
                              // }
                            />
                            {errors.email && touched.email ? (
                              <ErrorText>{errors.email}</ErrorText>
                            ) : (
                              <ErrorSpace />
                            )}
                          </InputDiv>
                        </FormField>
                        <FormField>
                          <InputDiv>
                            <Input
                              id="company"
                              type="company"
                              name="company"
                              placeholder="Company"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.company}
                              // onClick={() =>
                              //   trackCustomEvent({
                              //     action: 'company name form field click',
                              //     category: 'Homepage form',
                              //     label: 'company name form field click',
                              //   })
                              // }
                            />
                            {errors.company && touched.company ? (
                              <ErrorText>{errors.company}</ErrorText>
                            ) : (
                              <ErrorSpace />
                            )}
                          </InputDiv>
                          <InputDiv>
                            <Field name={'organization_type'}>
                              {({ form, field }) => {
                                return (
                                  <StyledSelect
                                    options={companyOptions}
                                    placeholder="Business Phase"
                                    // id="organization_type"
                                    name="organization_type"
                                    styles={customStyles}
                                    // type="organization_type"
                                    // clearable={false}
                                    // searchable={false}
                                    onChange={(option) => setFieldValue(field.name, option.value)}
                                    onBlur={handleBlur}
                                    // onClick={() =>
                                    //   trackCustomEvent({
                                    //     action: 'organization type form field click',
                                    //     category: 'Homepage form',
                                    //     label: 'organization type form field click',
                                    //   })
                                    // }
                                  />
                                );
                              }}
                            </Field>
                            {/* <StyledSelect
                    name="organization_type"
                    value={values.organization_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // style={{ display: 'block' }}
                  >
                    <option value="" label="Select" />
                    <option value="Idea Phase Startup" label="Idea Phase Startup" />
                    <option value="Development Phase Startup" label="Development Phase Startup" />
                    <option value="Existing Business" label="Existing Business" />
                  </StyledSelect> */}
                            {errors.organization_type && touched.organization_type ? (
                              <ErrorText>{errors.organization_type}</ErrorText>
                            ) : (
                              <ErrorSpace />
                            )}
                          </InputDiv>
                        </FormField>
                        <TextAreaField>
                          <TextAreaDiv>
                            <TextArea
                              id="message"
                              type="message"
                              name="message"
                              placeholder="Tell Us About Your Project"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                              // onClick={() =>
                              //   trackCustomEvent({
                              //     action: 'message form field click',
                              //     category: 'Homepage form',
                              //     label: 'message form field click',
                              //   })
                              // }
                            />
                            {errors.message && touched.message ? (
                              <ErrorText>{errors.message}</ErrorText>
                            ) : (
                              <ErrorSpace />
                            )}
                          </TextAreaDiv>
                        </TextAreaField>
                        <SubmitButton
                          type="submit"
                          disabled={isSubmitting}
                          // onClick={() =>
                          //   trackCustomEvent({
                          //     action: 'Form submission attempt',
                          //     category: 'Homepage form',
                          //     label: 'Form submission attempt',
                          //   })
                          // }
                        >
                          Submit
                        </SubmitButton>
                      </FormDiv>
                    </form>
                  )}
                </Formik>
              </FormContainer>
            </FormWrapper>
            <OurInfo>
              <RapptrText>Rapptr Labs</RapptrText>
              <SectionTextAddress>
                {OFFICE_ADDRESS} <br />
                {OFFICE_STATE_ZIP}
                <br />
                <StyledA href="tel:2129139626">(212) 913 9626</StyledA>
              </SectionTextAddress>
              <SocialsContainer>
                <SocialLink href="https://www.linkedin.com/company/rapptr" target="_blank">
                  <SocialsDiv>
                    <SocialImg src={linkedInLogo} alt="LinkedIn" />
                  </SocialsDiv>
                </SocialLink>
                <SocialLink href="https://www.facebook.com/rapptrlabs" target="_blank">
                  <SocialsDiv>
                    <SocialImg src={facebookLogo} alt="Facebook" />
                  </SocialsDiv>
                </SocialLink>
                <SocialLink href="https://twitter.com/rapptrlabs" target="_blank">
                  <SocialsDiv>
                    <SocialImg src={twitterLogo} alt="Twitter" />
                  </SocialsDiv>
                </SocialLink>
              </SocialsContainer>
            </OurInfo>
          </SegmentContent>
        </SegmentContainer>
      </DocContainer>
      <DarkFooter />
    </NewIndexLayout>
  );
};

const StyledA = styled.a`
  color: ${theme.ELECTRIC_BLUE};
`;

const TextArea = styled.textarea`
  display: flex;
  flex-direction: column;
  height: 92px;
  /* height: 47px; */
  width: 100%;
  /* max-width: 800px; */
  /* width: 100%; */
  padding: 20px;
  border-radius: 5.6px;
  border-width: 0;
  line-height: 1.1;
  border: 1px solid ${theme.SKY_BLUE};
`;

const TextAreaDiv = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  width: 100%;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;
  height: 22px;
  /* height: 47px; */
  width: 100%;
  /* width: 100%; */
  padding: 20px;
  border-radius: 5.6px;
  border-width: 0;
  border: 1px solid ${theme.SKY_BLUE};
`;

const FormContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px 0px 0px;
`;

const SubmitButton = styled(NewAppButton)`
  background-color: ${theme.MAIN_DARK_BLUE};
  border: none;
  border-radius: 7px;
  margin-right: auto;
  width: 130px;
  :hover {
    background-color: ${theme.SKY_BLUE};
    color: ${theme.MAIN_DARK_BLUE};
  }
  @media ${laptopSize} {
    /* width: 60vw; */
    margin-bottom: 30px;
  }
`;

const StyledSelect = styled(Select)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: center;
  /* height: 22px; */
  height: 40px;
  background-color: white;
  width: 100%;
  /* width: 100%; */
  /* padding: 20px; */
  border-radius: 5.6px;
  border-width: 0;

  & > div {
    padding-left: 8px;
  }
`;

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TextAreaField = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* width: 90vw; */
  /* height: 190px; */
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* max-width: 760px; */
  /* padding-bottom: 20px; */
`;

const ErrorText = styled(NewAppText)`
  height: 28px;
  font-size: 14px;
  color: red;
  margin: 0px;
`;

const ErrorSpace = styled.div`
  height: 28px;
  width: 1px;
`;

const FormField = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  width: 100%;
  @media ${laptopSize} {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

const RapptrText = styled(NewAppText)`
  color: ${theme.MAIN_DARK_BLUE};
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 4px;
  @media ${laptopSize} {
    margin-top: 30px;
    text-align: center;
    width: 100%;
  }
`;

const SocialImg = styled.img`
  /* filter: invert(100%); */
`;

const SocialLink = styled.a``;

const SocialsDiv = styled.div`
  background: url(${socialsBackground});
  height: fit-content;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialsContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
  @media ${laptopSize} {
    margin: auto;
    margin-bottom: 10px;
  }
`;

const OurInfo = styled.div`
  margin-left: 20px;
  @media ${laptopSize} {
    margin: 0px;
  }
`;

const FormWrapper = styled.div`
  width: 740px;
  max-width: 740px;
  padding-right: 30px;
  border-right: 2px solid #F1F1F1;
  @media ${laptopSize} {
    padding-right: 0px;
    width: 100%;
    /* max-width: 100%; */
    max-width: 768px;
    border-right: none;
    border-bottom: 2px solid #F1F1F1;
    padding-top: 30px;
  }
`;

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  /* padding-bottom: 40px; */
  width: 720px;
  @media ${laptopSize} {
    width: 80vw;
  }
`;

const SectionText = styled(NewAppText)`
  color: ${theme.MAIN_DARK_BLUE};
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
`;

const SectionTextAddress = styled(SectionText)`
  @media ${laptopSize} {
    width: 100%;
    text-align: center;
  }
`;

const SectionTitle = styled(NewAppTitle)`
  color: ${theme.MAIN_DARK_BLUE};
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
`;

const SegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 60px;
`;

const SegmentContent = styled.div`
  display: grid;
  grid-template-columns: 745fr 248fr;
  max-width: 100vw;
  padding: 30px;
  padding-top: 60px;
  /* max-width: 860px; */
  /* margin: 0px auto; */

  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
  }
`;

const HeaderText = styled(NewAppTitle)`
  width: 100%;
  text-align: center;
  background-color: ${theme.BACKGROUND_COLOR};
  color: ${theme.WHITE_COLOR};
  font-weight: 700;
  font-size: 62px;
  margin-bottom: 0px;
  padding-top: 77.5px;
  padding-bottom: 77.5px;
  margin-top: 120px;
  @media ${laptopSize} {
    margin-top: 80px;
  }
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding-top: 50px;
  padding-bottom: 150px;
  @media ${mobileSize} {
    padding: 30px 2rem;
  }
`;

const TermsText = styled(NewAppText)`
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
`;

const TermsHeading = styled(NewAppText)`
  margin: 0;
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 20px;
`;

const TermsSubheading = styled(NewAppText)`
  margin: 1rem auto;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
`;

const TermsListContainer = styled.div`
  ol {
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding: 0;
  }

  li:before {
    display: inline;
    content: counters(item, '.') '.';
    counter-increment: item;
    margin-right: 1rem;
  }

  ol > li:before {
    font-weight: bold;
  }
`;

const TermsListHeading = styled(NewAppText)`
  display: inline;
  margin-left: 1rem;
  font-weight: bold;
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  text-transform: uppercase;
`;

const TermsListText = styled(NewAppText)`
  display: inline;
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
`;

const DocContainer = styled.div`
  background: ${theme.WHITE_COLOR};
  /* max-width: 900px; */
  width: 100%;
  padding: 30px auto;
  /* padding-top: 120px; */

  @media ${mobileSize} {
    padding: 30px 2.5rem;
  }
`;

const NewIndexLayout = styled(IndexLayout)``;

const StyledLink = styled(Link)`
  cursor: pointer;
`;
